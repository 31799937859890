import React, {useEffect, useRef, useState} from "react";
import {message, Modal, notification} from "antd";
import {ExclamationCircleOutlined, InfoCircleTwoTone} from "@ant-design/icons";
import moment from "moment";
import {logout} from "../redux/modules/Auth/authActions";
import {store} from "../services/ReduxService";

const {confirm} = Modal;

export const setIntoStorage = info => {
    Object.keys(info).forEach(key => {
        localStorage.setItem(key, info[key]);
    });
};

export const getFromStorage = key => {
    return localStorage.getItem(key);
};

export const removeFromStorage = key => {
    return localStorage.removeItem(key);
};

export const clearStorage = () => {
    localStorage.clear();
};

export const getAuthToken = () => localStorage.getItem("token");

export const getFromSession = key => {
    return sessionStorage.getItem(key);
};

export const setInToSession = (key, value) => {
    sessionStorage.setItem(key, value);
};

export const clearSession = () => {
    sessionStorage.clear();
};

export const removeFromSession = key => {
    sessionStorage.removeItem(key);
};

export function setCookie(name, value) {
    document.cookie = name + "=" + encodeURIComponent(value);
}

export function getCookie(name) {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

// export const getPathByName = routeId => {
//   const route = routes.find(item => item.id === routeId) || {};
//   return route.path || ""
// };

export const uniqueArray = (array) => {
    array = array.filter((temp2, index, self) => {
        return self.indexOf(temp2) === index;
    });
    return array
};

export const deleteFromArray = (array, item, directIndex) => {
    const index = array.indexOf(item);
    if ((directIndex ? directIndex : index) > -1) {
        if (directIndex)
            array.splice(directIndex, 1);
        else
            array.splice(index, 1);
    }
    return array;
};

export const errorMessage = title => {
    message.error({
        content: title || " ",
        style: {
            position: "absolute",
            right: "0px",
            top: "100px"
        },
    });
};

export const successMessage = data => {
    message.success({
        content: data || "success",
        style: {
            position: "absolute",
            right: "0px",
            top: "50px",
            zIndex: '9999999999999999999999'
        }
    });
};

export const openNotification = (placement = 'topRight', message, description) => {
    notification.config({
        placement: placement
    })
    notification.open({
        message: message,
        description: description,
        icon: <InfoCircleTwoTone/>
    });
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validatePassword = (passWord) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    return re.test(passWord);
}

export const downloadCloudinaryFiles = (publicId, fileName) => {
    const downloadUrl = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDY_COLLECTION}/raw/upload/${publicId}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;
    link.click();
    window.open(downloadUrl);
}

export const handleLogout = () => {
    store.dispatch(logout());
};

export function downloadFile(event, api, name) {
    // axios.post(config.BASE_URL + api, data,
    //   {
    //     responseType: 'blob',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Content-Disposition': "attachment; filename=template.xlsx"
    //     }
    //   })
    //   .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', name);
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((error) => console.log(error));
}

export const confirmDelete = ({title, content, hideContent, onConfirm, onCancel}) => {
    confirm({
        title: title || 'Do you Want to delete these items?',
        icon: <ExclamationCircleOutlined/>,
        content: !hideContent && (content || 'Some descriptions'),
        onOk() {
            onConfirm();
        },
        onCancel() {
            onCancel();
            console.log('Cancel');
        },
    });
};

export const addSecondTODate = (second) => {
    return moment().add(second, "second")._d
};

export const validateLink = (link) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i');
    return !!pattern.test(link);
}

export const organisationId = () => {
    return getFromStorage('organisation_id')
}
export const parseJwt = (token) => {
    if (token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } else {
        return null;
    }
};

export const downloadFile2 = (dataUrl, filename) => {
    const url = window.URL.createObjectURL(dataUrl);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
};

export const firstLetterLowerCase = (text) => text[0].toLowerCase() + text.substring(1);
export const errorFieldToObject = (err) => {
    let error = {};
    if (!err[0]?.field)
        return err;
    err.forEach(item => {
        error = {...error, [firstLetterLowerCase(item?.field)]: item?.message}
    });
    return error;
}

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getWidth() {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const isEmpty = (obj) => Object.values(obj).some(x => x !== null && x !== '');

export const staticOptionsList = {
    platform: [{value: 'Android', label: 'Android'}],
    appCategory: [
        {label: 'Art & Design', value: 'Art & Design'},
        {label: 'Beauty', value: 'Beauty'},
        {label: 'Books & Reference', value: 'Books & Reference'},
        {label: 'Business', value: 'Business'},
        {label: 'Comics', value: 'Comics'},
        {label: 'Communications', value: 'Communications'},
        {label: 'Dating', value: 'Dating'},
        {label: 'Education', value: 'Education'},
        {label: 'Entertainment', value: 'Entertainment'},
        {label: 'Finance', value: 'Finance'},
        {label: 'Food & Drink', value: 'Food & Drink'},
        {label: 'Games', value: 'Games'},
        {label: 'Health & Fitness', value: 'Health & Fitness'},
        {label: 'Lifestyle', value: 'Lifestyle'},
        {label: 'Maps & Navigation', value: 'Maps & Navigation'},
        {label: 'Music & Audio', value: 'Music & Audio'},
        {label: 'Personalization', value: 'Personalization'},
        {label: 'Photography', value: 'Photography'},
        {label: 'Productivity', value: 'Productivity'},
        {label: 'Social', value: 'Social'},
        {label: 'Sports', value: 'Sports'},
        {label: 'Tools', value: 'Tools'},
        {label: 'Video Players & Editors', value: 'Video Players & Editors'},
    ],
    lockUnlock: [{label: 'Lock', value: 'true'}, {label: 'Un Lock', value: 'false'}],
    appFilterStatus: [
        {label: 'Publish On Play Store', value: 'publish'},
        {label: 'Reject On Play Store', value: 'reject'},
        {label: 'Removed On Play Store', value: 'removed'},
        {label: 'Suspend On Play Store', value: 'suspend'},
    ],
};

export const initialAppListState = {
    image: "",
    platform: "",
    accountName: "",
    accountId: "",
    appCategory: "",
    appName: "",
    packageName: "",
    apiKey: "",
    apiNote: "",
    versionCodeSwitch: false,
    versionCode: "",
    redirectToOtherAppSwitch: false,
    redirectToOtherApp: "",
    privacyPolicyLink: "",
    appAccountLink: "",
    extraJSONData: "",
    createAppStep: false,
    appDetailsStep: false,
    monetizeShowAdInApp: false,
    showAdInApp: false,
    splashAd: false,
    splashAdFormat: "",
    backPressAd: false,
    backPressAdFormat: "",
    howToShowAd: "",
    adPlatformSeq: [],
    adPlatformAlternateSeq: [],
    adPlatformName: [],
    adPlatformNameForAlternate: [],
    appStatus: 'Not Publish',
    platformApply: "",
    versionNameToBlockAd: "",
    adClickCountHomePage: null,
    adClickCountInnerPage: null,
    backPressAdLimit: null,
    placement: [],
    monetizeSettingStep: false,
    parameter: [],
    downloadedCount: 0,
    extraField: []
};

export const appStatusClass = (data, custom, isBoder) => {
    if (custom)
        switch (data && data.toLowerCase()) {
            case 'publish':
                return 'bg-green-button';
            case 'reject':
                return 'bg-red';
            case 'removed':
                return 'bg-volcano';
            case 'suspend':
                return 'bg-orangered';
            default:
                return 'bg-geekblue';
        }
    else if (isBoder)
        switch (data && data.toLowerCase()) {
            case 'publish':
                return 'border-color-green';
            case 'reject':
                return 'border-color-red';
            case 'removed':
                return 'border-color-volcano';
            case 'suspend':
                return 'border-color-orangered';
            default:
                return 'border-color-geekblue';
        }
    else
        switch (data && data.toLowerCase()) {
            case 'publish':
                return 'green';
            case 'reject':
                return 'red';
            case 'removed':
                return 'volcano';
            case 'suspend':
                return 'orange';
            default:
                return 'geekblue';
        }
};

export const customData = (type, appData) => {
    let data = {};
    switch (type) {
        case "jksData":
            data = {
                title: 'JKS',
                previousLabel: 'Previous Uploaded JKS',
                type: type,
                dataSource: appData[type] ? appData[type] : {}
            };
            break;
        case "sourceCodeData":
            data = {
                title: 'Source Code',
                previousLabel: 'Previous Uploaded Source Code',
                type: type,
                dataSource: appData[type] ? appData[type] : {}
            };
            break;
        case "appDesignData":
            data = {
                title: 'App Design',
                previousLabel: 'Previous Uploaded App Design',
                type: type,
                dataSource: appData[type] ? appData[type] : {}
            };
            break;
    }
    return data;
};

export const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer
            = setTimeout(() => func.apply(context, args), delay)
    }
};

export const isBoolean = val => 'boolean' === typeof val || ['true', 'false'].includes(val?.toString());

export const convertValueBoolToString = (val) => isBoolean(val) ? '' : val;

export const consoleInitialState = {
    managedBy: "",
    email: "",
    status: "",
    password: "",
    consoleName: "",
    mobileNumber: "",
    device: "",
    remarks: "",
    blogger: "",
    privacy: ""
};

export function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

function getNameById(id, adPlatformNameForAlternate) {
    const item = (adPlatformNameForAlternate || []).find(item => item._id === id);
    return item ? item?.platformName : null;
}

export const appResponseObj = (orignalObj) => {

    function convertToKeyValueObject(arr) {
        const result = {};
        (arr || []).forEach(item => {
            result[item.fieldKey] = item?.fieldType === "enableDisable"
                ? item?.filedResponseValue
                    ? Number(item?.filedResponseValue)
                    :
                    0
                :
                item?.filedResponseValue
                    ?
                    item?.filedResponseValue
                    :
                    item?.fieldValue;
        });
        return result;
    }

    function checkJSON(value) {
        if (value && JSON.parse(value)) {
            return JSON.parse(value);
        }
        return null
    }

    const convertedObject = {};
    orignalObj?.placement.forEach(item => {
        convertedObject[item?.placementName] = {
            ad_loadAdIdsType: item?.loadAdIds,
            AppID: item?.appId,
            Banner1: item?.banner,
            Interstitial1: item?.interstitial,
            Native1: item?.native,
            RewardedVideo1: item?.rewardedVideo,
            RewardedInterstitial1: item?.rewardedInterstitial,
            NativeBanner1: item?.nativeBanner,
            AppOpen1: item?.appOpen,
        };
    });

    function convertParameterObject(arr) {
        const result = {};
        (arr || []).forEach(item => {
            result[item?.parameterName] = item?.parameterValue
        });
        return result;
    }

    return ({
        APP_SETTINGS: {
            app_name: orignalObj?.appName,
            app_packageName: orignalObj?.packageName,
            app_accountLink: orignalObj?.appAccountLink,
            app_logo: orignalObj?.image,
            app_status: orignalObj?.appStatus,
            app_privacyPolicyLink: orignalObj?.privacyPolicyLink,
            app_needInternet: orignalObj?.needInternet?.toString() === 'true' ? 1 : 0,
            app_updateAppDialogStatus: orignalObj?.versionUpdateDialog?.toString() === 'true' ? 1 : 0,
            app_versionCode: orignalObj?.versionCode,
            app_redirectOtherAppStatus: orignalObj?.redirectToOtherAppSwitch?.toString() === 'true' ? 1 : 0,
            app_newPackageName: orignalObj?.redirectToOtherApp,
            app_dialogBeforeAdShow: orignalObj?.showDialogBeforeAd?.toString() === 'true' ? 1 : 0,
            app_adShowStatus: orignalObj?.monetizeShowAdInApp?.toString() === 'true' ? 1 : 0,
            app_AppOpenAdStatus: orignalObj?.splashAd?.toString() === 'true' ? 1 : 0,
            app_splashAdType: orignalObj?.splashAdFormat,
            app_backPressAdStatus: orignalObj?.backPressAd?.toString() === 'true' ? 1 : 0,
            app_backPressAdType: orignalObj?.backPressAdFormat,
            app_howShowAd: orignalObj?.howToShowAd === "fixSequence" ? 1 : 0,
            app_ad_analysis: orignalObj?.appAdAnalysis?.toString() === 'true' ? 1 : 0,
            app_adPlatformSequence: (orignalObj?.adPlatformSeq || [])?.map(id => getNameById(id, orignalObj?.adPlatformName))?.filter((value) => value).join(','),
            app_alernateAdShow: (orignalObj?.adPlatformAlternateSeq || [])?.map(id => getNameById(id, orignalObj?.adPlatformNameForAlternate))?.filter((value) => value).join(','),
            ...convertToKeyValueObject(orignalObj?.extraField),
            ...convertParameterObject(orignalObj?.parameter),
        },
        PLACEMENT: {...convertedObject},
        EXTRA_DATA: checkJSON(orignalObj?.extraJSONData)
    })
};